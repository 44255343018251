export const paymentsServiceUrl: string | undefined = process.env.REACT_APP_PAYMENTS_SERVICE_URL
export const razorPayCheckoutLib: string = "https://checkout.razorpay.com/v1/checkout.js"

export const globalVars = {
    PAYMENTS_SERVICE_URL: process.env.REACT_APP_PAYMENTS_SERVICE_URL,
    AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB: process.env.REACT_APP_AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB,
}

export const authorizeDotNetConfig = [
    {
        accountId: "hfi_us_authorizenet",
        apiLoginId: process.env.REACT_APP_AUTHORIZE_DOT_NET_HI_API_LOGIN_KEY,
        iframeClientKey: process.env.REACT_APP_AUTHORIZE_DOT_NET_HI_CLIENT_KEY,
    }
]
