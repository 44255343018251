import { useEffect } from "react";

const PayuPG = (props: { data: any, queryParams: any }) => {
    const { data } = props;
    const { payment_gateway_url }: { payment_gateway_url?: string } = data;
    const { payment_gateway_request_method }: { payment_gateway_request_method?: string } = data;
    const { payment_gateway_request_post_param_map }: { payment_gateway_request_post_param_map?: any } = data;

    useEffect(() => {
        if (payment_gateway_request_method === "GET") {
            if (payment_gateway_url) {
                window.location.href = payment_gateway_url;
            }
        }
        if (payment_gateway_request_method === "POST") {
            const form: any = document.createElement("form");
            form.method = payment_gateway_request_method;
            form.action = payment_gateway_url;

            Object.keys(payment_gateway_request_post_param_map).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(payment_gateway_request_post_param_map, key)) {
                    const hiddenField = document.createElement("input");
                    hiddenField.type = "hidden";
                    hiddenField.name = key;
                    hiddenField.value = payment_gateway_request_post_param_map[key];
                    form.appendChild(hiddenField);
                }
            });
            document.body.appendChild(form);
            form.submit();
        }
    }, [payment_gateway_url, payment_gateway_request_method, payment_gateway_request_post_param_map])

    return null
}

export default PayuPG;