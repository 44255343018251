import { authorizeDotNetConfig, globalVars } from "Config";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { loadScript } from "utils";

const AuthorizeDotNetPG = (props: { data: any, queryParams: any }) => {
    const { data } = props;
    const [searchParams] = useSearchParams();
    const isApiCalledRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessgaeText, setErrorMessage] = useState("");
    const { PAYMENTS_SERVICE_URL, AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB } = globalVars;
    const accountId = useMemo(() => searchParams?.get("account_id"), [searchParams]);
    const purpose = useMemo(() => searchParams?.get("purpose"), [searchParams]);
    const { payment_gateway_url }: { payment_gateway_url?: string } = data;
    const gatewayUrlParam = new URLSearchParams(payment_gateway_url?.split("?")?.[1]);
    const trkId = gatewayUrlParam.get('trkId');
    const donationAmount = gatewayUrlParam.get('amount');
    const donationCurrency = gatewayUrlParam.get('currency');
    const authorizePaymentConfig = authorizeDotNetConfig?.find((p)=>p?.accountId === accountId)

    const addCustomeBlock = (key: string, content: string) => {
      let element = document.createElement("div");
      element.setAttribute("id", key);
      element.style.maxHeight = "65px";
      element.innerHTML = content;
      return element;
    };
    
    useEffect(() => {
      const loadAuthorizeScript  = async () => {
        const res = AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB ? await loadScript(AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB) : null;
        if (!res) {
            alert('Authorize Dot Net pay SDK failed to load')
            return false;
        }
        setTimeout(() => {
          showAuthorizeDotNet();
        }, 1000);
        return true;
      }

      const showAuthorizeDotNet = async () => {
        const acceptContainer = document.getElementById("AcceptUIContainer");
          const headerContainerTag = document.getElementById("headerContainer");
          if (acceptContainer) {
            acceptContainer.style.minHeight = "500px";
            acceptContainer.style.top = "35%";
            const headerContent = addCustomeBlock("headerContainer", `<div style="margin-top:20px">
            <p style="font-size: 16pt; font-weight: bold; padding:10px; color: #666;border-bottom: 1px solid #cbcbcb;">Amount: ${donationAmount} ${donationCurrency}</p>
            </div>`);
            if (!headerContainerTag) {
              acceptContainer.insertBefore(
                headerContent,
                acceptContainer.firstElementChild
              );
            }
            if (authorizePaymentConfig?.iframeClientKey && authorizePaymentConfig?.apiLoginId) {
              acceptContainer.classList.add("show");
            }
          }
      }
      loadAuthorizeScript();
    }, [payment_gateway_url, AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB, authorizePaymentConfig, donationAmount, donationCurrency])

    const createRequest = (url: any, data: any, method: string) => {
        try {
          const reqParam = {
            method,
            headers: new Headers({ "Content-type": "application/json" }),
          };
          if (method === "POST") {
            Object.assign(reqParam, {
              body: JSON.stringify(data),
            });
          }
    
          return fetch(url, reqParam)
            .then((res) => res.ok && res.json().then((json) => json))
            .catch((err) => {
              return `${err}url:${url}`;
            });
        } catch (e) {}
        return true;
      }
      const makeRedirectCall = async (resp: any) => {
        try {
          const {
            amount,
            currency,
            paymentTransactionId,
            paymentTrackingId,
          } = resp;
          const redirectData = {
            trackingId: paymentTrackingId,
            currency,
            amount,
            transactionId: paymentTransactionId || "null",
          };
          const redirectAckUrl = `${PAYMENTS_SERVICE_URL}/open/authorizeNet/redirectAck`;
          const url = new URL(redirectAckUrl);
          url.search = new URLSearchParams(redirectData).toString();
          const method = "GET";
          let res = await createRequest(url, "", method);
            if (res && (res.successUrl || res.failureUrl)) {
                window.location.href = res.successUrl || res.failureUrl;
            }
            
        } catch (e) {}
      };


    function responseHandler(response: any) {
        setIsLoading(true);
        try {
          isApiCalledRef.current = !isApiCalledRef.current;
          if (isApiCalledRef.current === true) {
            if (response.messages.resultCode === "Error") {
              setIsLoading(false);
              let i = 0;
              while (i < response.messages.message.length) {
                setErrorMessage(response.messages.message[i].text);
                i += 1;
              }
            } else {
              const postData = {
                currency: donationCurrency,
                amount: donationAmount,
                trackingId: trkId,
                otherDetail: {
                    purpose,
                },
                opaqueData: {
                  dataDescriptor: response.opaqueData.dataDescriptor,
                  dataValue: response.opaqueData.dataValue,
                },
                paymentGatewayAccount: { name: "authorizenet_donation" },
              };
              const method = "POST";
              const makePaymentUrl = `${PAYMENTS_SERVICE_URL}/open/authorizeNet/makePayment`
                setTimeout(async()=>{ let res = await createRequest(makePaymentUrl, postData, method);
                  if(res) res && makeRedirectCall(res);}, 5000);
            }
          }
        } catch (e) {}
    }
    
    useEffect(() => {
      window.responseHandler = responseHandler;
    });

    return (
        <React.Fragment>
            <form>
                <button
                    style={{ display: "none" }}
                    id="acceptUIPayBtn"
                    type="button"
                    className="AcceptUI"
                    data-billingaddressoptions='{"show":true, "required":false}'
                    data-apiloginid={authorizePaymentConfig?.apiLoginId}
                    data-clientkey={authorizePaymentConfig?.iframeClientKey}
                    data-acceptuiformbtntxt="Submit"
                    data-acceptuiformheadertxt="Card Information"
                    data-paymentoptions='{"showCreditCard": true}'
                    data-responsehandler="responseHandler"
                >
                pay
                </button>
            </form>
            {isLoading &&
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
              <div>Authorize.net Secure Payment..</div>
              <div>Please don't hit back button or refresh</div>
            </div>}
            {errorMessgaeText && <div>{errorMessgaeText} Try again later.</div>}
        </React.Fragment>
    )
}

export default AuthorizeDotNetPG;