import React from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useFormik } from "formik";

export interface PaymentOrder {
  details: string;
  clientSecret: string;
}

export interface Props {
  onSubmit: (order: PaymentOrder) => void;
}

const TestForm = (props: Props) => {
  const formik = useFormik({
    initialValues: {
      details:
        "account_id=<Account Id>\nclient_reference_id=<client_reference_id>\namount=100\nbuyer_name=Test\ncurrency_code=INR\nemail=aasaaas@mailinator.com\nphone=8888888888\npurpose=Test\nredirect_url=http://localhost:8080/open/test-result.html\nudf1=udf1\nudf2=udf2",
      clientSecret: "",
    },
    onSubmit: (values, actions) => {
      props.onSubmit(values);
    },
  });

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Client Secret</Form.Label>
          <Form.Control
            id="clientSecret"
            name="clientSecret"
            onChange={formik.handleChange}
            value={formik.values.clientSecret}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Order Values</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            id="details"
            name="details"
            onChange={formik.handleChange}
            value={formik.values.details}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default TestForm;
