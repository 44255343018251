export const loadScript = (src: string) => {
    return new Promise(resolve => {
        const script = document.createElement("script")
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

export const postUrl = (path: string, params: any, method = "post", target = "") => {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;
    form.target = target;
    Object.keys(params).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = params[key] instanceof Object ? JSON.stringify(params[key]) : params[key];
            form.appendChild(hiddenField);
        }
    });
    document.body.appendChild(form);
    form.submit();
}
