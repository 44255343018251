import { globalVars, razorPayCheckoutLib } from "Config";
import { useCallback, useEffect } from "react";
import { loadScript, postUrl } from "utils";

const RazorPayPG = (props: { data: any, queryParams: any }) => {
    const { data, queryParams } = props
    const { id: order_id, amount, currency, origin, client_id  }: { id: string; amount: string; currency: string, origin: string, client_id: string } = data.params
    const { buyer_name: name, email, phone: contact, purpose }: { buyer_name: string; email: string; phone: string, purpose: string } = queryParams
    const { PAYMENTS_SERVICE_URL } = globalVars

    const failureCall = useCallback(() => {
        const param = {
            error: {
                description: "Transaction has been cancelled by user",
                metadata: {
                    order_id,
                },
            },
            amount,
            currency
        }

        postUrl(`${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`, param)
    }, [amount, currency, order_id, PAYMENTS_SERVICE_URL])

    const callRedirect = useCallback((res: object) => {
        postUrl(`${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`, res)
    }, [PAYMENTS_SERVICE_URL])

    useEffect(() => {
        const displayRazorPay = async () => {
            const res = await loadScript(razorPayCheckoutLib)

            if (!res) {
                alert('Razor pay SDK failed to load')
                return
            }

            const options = {
                //Razorpay client ID
                key: client_id,
                amount,
                currency,
                name,
                description: purpose,
                image: "https://cdn-web.heartfulness.org/en/wp-content/uploads/2019/04/05114150/LTM-logo_updated.png",
                order_id,
                callback_url: `${PAYMENTS_SERVICE_URL}/open/razorpay/redirectAck`,

                handler: (response: Object) => {
                    callRedirect(response)
                },

                prefill: {
                    name,
                    email,
                    contact,
                },
                retry: {
                    enabled: false
                },
                modal: {
                    ondismiss: () => {
                        failureCall()
                    }
                },
                notes: {
                    origin
                },
                theme: {
                    color: "#3399cc",
                },
            }

            const _window = window as any
            const paymentObject = new _window.Razorpay(options)

            paymentObject.on("payment.failed", (response: { error: object }) => {
                console.log(response);
                callRedirect({
                    error: {
                        ...response.error,
                        metadata: { order_id }
                    }, amount, currency
                })
            });

            paymentObject.open();
        }

        displayRazorPay()
    }, [amount, callRedirect,
        contact, email, failureCall,
        name, order_id, currency, purpose,
        PAYMENTS_SERVICE_URL, client_id, origin])

    return null
}

export default RazorPayPG;