import { useEffect } from "react";

const InstamojoPG = (props: { data: any, queryParams: any }) => {
    const { data } = props;
    const { payment_gateway_url }: { payment_gateway_url?: string } = data;

    useEffect(() => {
        const redirectInstamojo = async () => {
            if (payment_gateway_url) {
                window.location.href = payment_gateway_url;
            }
        }
        redirectInstamojo();
    }, [payment_gateway_url])

    return null
}

export default InstamojoPG;