import React from "react";
import { Routes, Route } from "react-router-dom";
import ProcessingPage from "views/RequestProcessingPage";
import TestForm from "views/TestingForm";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="create-order" element={<ProcessingPage />} />
        <Route path="test" element={<TestForm />} />
        <Route path="/" element={<ProcessingPage />} />
      </Routes>
    </div>
  );
};

export default App;
