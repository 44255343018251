import React, { useCallback } from "react";
import forge from "node-forge";
import FormComponent, { PaymentOrder } from "./FormComponent";
import { useNavigate } from "react-router-dom";

const TestForm = () => {
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (order: PaymentOrder) => {
      const params: string[] = order.details.split("\n");

      const paramValues = params
        .map((v) => v.split("=").slice(1).join("="))
        .join("|");

      const urlParams = new URLSearchParams();

      params.forEach((v) => {
        const values = v.split("=");
        urlParams.append(values[0], values.slice(1).join("="));
      });

      const hmac = forge.hmac.create();
      hmac.start("sha256", order.clientSecret);
      hmac.update(paramValues);

      urlParams.append("hash", hmac.digest().toHex());

      navigate({
        pathname: "/",
        search: `?${urlParams.toString()}`,
      });
    },
    [navigate]
  );
  return <FormComponent onSubmit={onSubmit}></FormComponent>;
};

export default TestForm;
